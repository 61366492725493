const About = (props) => {
  return (
    <div ref={props.aboutRef} className="about">
      <img src={props?.profile?.fields?.image?.[0]?.thumbnails?.large?.url}  alt="meredith drake's profile"/>
      <h2>About The Artist</h2>
      <p>Meredith Drake is a visual artist currently residing in Denver, and born and raised in the Kansas City area.</p>
    </div>
  )
}

export default About;
