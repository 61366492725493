import { useEffect, useState } from 'react';

const Nav = (props) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [style, setStyle] = useState({ opacity: 1, zIndex: 1000, left: 0 });

  const updateMenu = () => {
    const newStyle = window.innerWidth > 600
      ? {
        opacity: 1,
        zIndex: 1000,
        left: 0,
      }
      : displayMenu
      ? {
        opacity: 1,
        zIndex: 1000,
        left: 0,
      }
      : {
        opacity: 0,
        zIndex: -1000,
        left: -1000
      };
    setStyle(newStyle);
  }

  useEffect(() => {
    window.addEventListener('resize', updateMenu);
    return () => window.removeEventListener('resize', updateMenu);
  }, []);

  useEffect(() => {
    updateMenu();
  }, [displayMenu]);

  const scrollAndSet = (ref) => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: ref.current.offsetTop - 42
    });
    setDisplayMenu(false);
  }

  const scrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0
    });
    setDisplayMenu(false);
  }

  return (
    <nav>
      <p onClick={scrollToTop}>Meredith Drake</p>
      <aside id="menu-desktop" style={style}>
        <i onClick={() => setDisplayMenu(false)} id="menu-mobile" className="fa fa-bars"></i>
        <p onClick={() => scrollAndSet(props.sfxRef)}>SFX</p>
        <p onClick={() => scrollAndSet(props.beautyRef)}>Beauty</p>
        <p onClick={() => scrollAndSet(props.aboutRef)}>About</p>
        <a onClick={() => setDisplayMenu(false)} href="mailto:drakemeredithm@gmail.com">Contact</a>
        <a href="https://www.instagram.com/honeyretrograde">
          <i className="fa fa-instagram"></i>
        </a>
      </aside>
      <i onClick={() => setDisplayMenu(true)} id="menu-mobile" className="fa fa-bars"></i>
    </nav>
  )
};

export default Nav;
