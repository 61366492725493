import { useEffect, useState, useRef } from "react";
import axios from "axios";
import About from "./components/About";
import Footer from "./components/Footer";
import Grid from "./components/Grid";
import Nav from "./components/Nav";
import "./App.css";

function App() {
  const [beautyPics, setBeautyPics] = useState([]);
  const [sfxPics, setSFXPics] = useState([]);
  const [beautyRef, sfxRef, aboutRef] = [useRef(), useRef(), useRef()];

  const {
    REACT_APP_AIRTABLE_BASE: base,
    REACT_APP_AIRTABLE_KEY: key,
  } = process.env;

  useEffect(() => {
    const getBeautyPics = async () => {
      const airtableURL = `https://api.airtable.com/v0/${base}/beauty?view=Grid%20view`;
      const config = {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      };
      const resp = await axios.get(airtableURL, config);
      setBeautyPics(resp.data.records);
    };
    getBeautyPics();
  }, [base, key]);

  useEffect(() => {
    const getSFXPics = async () => {
      const airtableURL = `https://api.airtable.com/v0/${base}/sfx?view=Grid%20view`;
      const config = {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      };
      const resp = await axios.get(airtableURL, config);
      setSFXPics(resp.data.records);
    };
    getSFXPics();
  }, [base, key]);

  return (
    <div className="App">
      <Nav beautyRef={beautyRef} sfxRef={sfxRef} aboutRef={aboutRef}/>
      <main>
        <Grid myRef={sfxRef} title="SFX" photos={sfxPics} />
        <Grid myRef={beautyRef} title="Beauty" photos={beautyPics} />
      </main>
      <About profile={beautyPics[0]} aboutRef={aboutRef}/>
      <Footer/>
    </div>
  );
}

export default App;
