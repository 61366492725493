import { useMemo, useState } from "react";
import { useResize } from "@soleilyasmina/hooks";
import GridItem from "./GridItem";
import Loading from "./Loading";

const Grid = (props) => {
  const [columns, setColumns] = useState(() => {
    if (window.innerWidth > 1080) {
      return 4;
    } else if (window.innerWidth > 768) {
      return 3;
    } else if (window.innerWidth > 425) {
      return 2;
    } else {
      return 1;
    }
  });

  useResize(() => {
    if (window.innerWidth > 1080) {
      setColumns(4);
    } else if (window.innerWidth > 768) {
      setColumns(3);
    } else if (window.innerWidth > 425) {
      setColumns(2);
    } else {
      setColumns(1);
    }
  });

  const splitPhotos = useMemo(() => (
    props.photos.reduce(
      (list, photo, i) => {
        list[i % columns].push(photo);
        return list;
      },
      [[], [], [], []]
    )), [props.photos, columns]);

  return (
    <div
      ref={props.myRef}
      className="grid-container"
      id={props.title.toLowerCase()}
    >
      <div className="name">{props.title}</div>
      <div className="grid">
        {props.photos.length ? (
          <>
            {splitPhotos.map((photos) => (
              <section>
                {photos.map((p) => (
                  <GridItem p={p} />
                ))}
              </section>
            ))}
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Grid;
