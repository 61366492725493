const Footer = () => {
  const scroll = () => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  }
  return (
    <footer onClick={() => scroll()}>
      <i className="fa fa-arrow-up"></i>
    </footer>
  )
}

export default Footer;
