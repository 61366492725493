const GridItem = (props) => {
  const { index, loading, p } = props;
  if (loading) {
    return (
      <div className="loading" key={index}>
      </div>
    )
  }
  return (
    <div className="grid-item nolight" key={p.id} >
      <img src={p?.fields?.image?.[0]?.thumbnails?.large?.url} alt="makeup" />
      <div>
        {p?.fields?.title}
      </div>
    </div>
  )
}

export default GridItem;
