import GridItem from "./GridItem";

const Loading = () => {
  return (
    <section>
      {[...new Array(12)].map((a, i) => <GridItem index={i} loading />)}
    </section>
  );
};

export default Loading;
